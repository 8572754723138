// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/terms.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".terms{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n     background-size:cover;\n     background-repeat: no-repeat;\n     background-position: center center;\n     /* padding: 180px 0; */\n     padding: 150px 0 100px 0;\n}\n\n.terms h1{\n    font-weight: 600;\n    font-size: 76px;\n    line-height: 80px;\n    color: #FFFFFF;\n}\n\n.terms-enquries p{\n    max-width:714px;\n    font-style: italic;\n    font-weight: 300;\n    font-size: 16px;\n    line-height: 24px;\n    color: rgba(226, 29, 0, 0.8);\n}\n@media screen and (max-width: 1024px) {\n    .terms h1{\n        font-size: 55px;\n    }\n  }", "",{"version":3,"sources":["webpack://src/views/Terms/terms.css"],"names":[],"mappings":"AAAA;IACI,yDAAmD;KAIlD,qBAAqB;KACrB,4BAA4B;KAC5B,kCAAkC;KAClC,sBAAsB;KACtB,wBAAwB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;AAChC;AACA;IACI;QACI,eAAe;IACnB;EACF","sourcesContent":[".terms{\n    background-image: url(\"../../assets/img/terms.png\");\n     -webkit-background-size: cover;\n     -moz-background-size: cover;\n     -o-background-size: cover;\n     background-size:cover;\n     background-repeat: no-repeat;\n     background-position: center center;\n     /* padding: 180px 0; */\n     padding: 150px 0 100px 0;\n}\n\n.terms h1{\n    font-weight: 600;\n    font-size: 76px;\n    line-height: 80px;\n    color: #FFFFFF;\n}\n\n.terms-enquries p{\n    max-width:714px;\n    font-style: italic;\n    font-weight: 300;\n    font-size: 16px;\n    line-height: 24px;\n    color: rgba(226, 29, 0, 0.8);\n}\n@media screen and (max-width: 1024px) {\n    .terms h1{\n        font-size: 55px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
